.inner.reviews {
    section.hero {

    }
    section.main-content {
        .testi-buttons {
            @extend .container;
            justify-content: space-between;
            padding-bottom: gutter()*2;
            @include screen-md {
                justify-content: center;
                .button {
                    margin-bottom: gutter();
                }
            }
        }
        .testi-wrap {
            .item {
                @include flex(wrap);
                border-top: 1px solid #000;
                &:last-child {
                    border-bottom: 1px solid #000;                
                }
                .author {
                    width: span(3 of 12);
                    background: $blue;
                    position: relative;
                    @include screen-md {
                        width: 100%;
                        min-height: 300px;
                    }
                    &:after {
                        content: "";
                        @include position(absolute, 50%, 0);
                        width: 70px;
                        height: 70px;
                        background: $blue;
                        transform: translate(-50%, -50%) rotate(45deg);
                        z-index: 1;
                        @include screen-md {
                            top: 100%;
                            left: 50%;
                        }
                    }
                    .author-inner {
                        @include position(absolute, 50%, 50%);
                        @include translate(-50%, -50%);
                        text-align: center;
                        z-index: 2;
                        .img-wrap {
                            width: 150px;
                            height: 150px;
                            border: 1px solid #000;
                            position: relative;
                            margin-bottom: gutter();
                            padding: gutter();
                            @include screen-md {
                                margin: 0 auto gutter();
                            }
                            &:before {
                                content: "";
                                @include position(absolute, -10px, $right: 20%);
                                background: $blue;
                                height: 20px;
                                width: 40px;

                            }
                            &:after {
                                content: "";
                                @include position(absolute, $bottom: -10px, $left: 20%);
                                background: $blue;
                                height: 20px;
                                width: 40px;

                            }
                        }
                        h3, h4 {
                            width: 100%;
                        }
                        h3 {
                            font: bold $h4;
                            small {
                                font-size: 0.8em;
                                display: block;
                            }
                        }
                    }
                }
                &:nth-child(2n) {
                    .author {
                        background: $green;
                        @include screen-xl {
                            order: 99;
                        }
                        &:after {
                            background: $green;
                            @include screen-xl {
                                left: 100%;
                            }
                        }
                        .img-wrap {
                            &:after,
                            &:before {
                                background: $green;
                            }
                        }
                    }
                }
                &:nth-child(3n) {
                    .author {
                        background: $grey-2;
                        &:after {
                            background: $grey-2;
                        }
                        .img-wrap {
                            &:after,
                            &:before {
                                background: $grey-2;
                            }
                        }
                    }
                }
                &:nth-child(4n) {
                    .author {
                        background: $blue;
                        &:after {
                            background: $blue;
                        }
                        .img-wrap {
                            &:after,
                            &:before {
                                background: $blue;
                            }
                        }
                    }
                }
            }
            .review {
                min-height: 330px;
                width: span(9 of 12);
                margin-right: gutter();
                @include flex(wrap, center, center);
                position: relative;
                p {
                    font: $p1;
                    padding: 60px 100px;
                }
                @include screen-md {
                    width: 100%;
                    margin-right: 0;
                    p {
                        padding: 60px gutter();
                    }
                }
            } 
        }
        .load-more {
            padding: 60px;
            @include screen-md {
                padding: 20px;
            }
            .button {
                margin: 0 auto;
            }
        }
    }

    .testimonial-popup {
        @include position(fixed, 50%, 50%);
        width: 700px;
        max-width: 90vw;
        height: auto;
        max-height: 90vh;
        padding: 60px;
        background: #FFF;
        @include translate(-50%, -50%);
        z-index: 999999;
        opacity: 0;
        pointer-events: none;
        transition: all 350ms ease-in-out;
        @include screen-md {
            height: 67vw;
            padding: 0;
        }
        &.active {
            opacity: 1;
            pointer-events: all;
        }
        .form-wrap {
            width: 100%;
            height: 100%;
            min-height: 600px;
            @include flex(wrap, center, center);
            form {
                .wpcf7-form-control-wrap {
                    display: block;
                    margin-bottom: gutter();
                    input, textarea {
                        appearance: none;
                        width: 100%;
                        background: transparent;
                        border-radius: 0;
                        border: 1px solid #000;
                        font: $p1;
                        font-size: 17px;
                        padding: 0 10px;
                    }
                    input {
                        height: 35px;
                    }
                }
                .submit-btn {
                    margin: 0 auto;
                }
                .wpcf7-mail-sent-ok {
                    display: none !important;
                }
            }
            .form-feedback {
                display: none;
                h3 {
                    font: $h3;
                    text-align: center;
                }
            }
        }
    }

    .testimonial-popup-overlay {
        @include position(fixed, 0, 0);
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.6);
        z-index: 999998;
        opacity: 0;
        pointer-events: none;
        transition: all 350ms ease-in-out;
        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }
}