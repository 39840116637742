.inner.register {
    // header, footer, .breadcrumbs {
    //     display: none !important;
    // }
    section.main-content {

        .reg-form {
            @extend .container;
            width: 100%;
            .right-side {
                width: 30%;
                @include flex(wrap, $direction: column);
                .block {
                    @include flex(wrap, center, center, column);
                    height: 33.333%;
                    min-height: 300px;
                    padding: 60px 60px 90px;
                    position: relative;
                    @include screen-md {
                        // width: 33.333%;
                        padding: 10px 10px 60px;
                    }
                    &:after {
                        content: "";
                        @include position(absolute, 50%, 0);
                        transform: translate(-50%, -50%) rotate(45deg);
                        width: 60px;
                        height: 60px;
                        @include screen-md {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .number {
                        font: bold 190px/1 $mainFont;
                        z-index: 2;
                        @include screen-md {
                            font: bold 90px/1 $mainFont;
                        }
                    }
                    h2 {
                        // font: bold 100px $mainFont;
                        font: bold $h1;
                        line-height: 1;
                        max-width: 100%;
                        width: 300px;
                        text-align: center;
                        z-index: 2;
                        @include screen-md {
                            font: bold $h4;
                        }
                    }
                    &.blue {
                        background: $blue;
                        &:after {
                            background: $blue;
                        }
                        .number {
                            color: #FFF;
                        }
                        h2 {
                            color: #000;
                        }
                    }
                    &.white {
                        background: white;
                        &:after {
                            background: white;
                        }
                        .number {
                            color: #000;
                        }
                        h2 {
                            color: #000;
                        }
                    }
                    &.green {
                        background: $green;
                        &:after {
                            background: $green;
                        }
                        .number {
                            color: #FFF;
                        }
                        h2 {
                            color: #000;
                        }
                    }
                }
            }
            .form-wrap {
                width: 100%;
                form {
                    .form-group {
                        padding: 0 120px;
                        width: 100%;
                        @include screen-md {
                            padding: 0;
                        }
                        .input-wrap {
                            width: 100%;
                            label {
                                font: $p1;
                                position: relative;
                                &:before {
                                    content: "";
                                    @include position(absolute, 11px, $right: -20px);
                                    width: 8px;
                                    height: 8px;
                                }
                            }
                            input, select {
                                appearance: none;
                                width: 100%;
                                background: transparent;
                                border-radius: 0;
                                border: 1px solid #000;
                                height: 35px;
                                font: $p1;
                                font-size: 17px;
                            }
                            .select-wrap {
                                position: relative;
                                width: 100%;
                                margin-bottom: 30px;
                                &:before {
                                    content: "";
                                    box-sizing: border-box;
                                    @include position(absolute, 50%, 15px);
                                    @include translate(0, -30%);
                                    width: 10px;
                                    height: 10px;
                                    border: {
                                        top: 10px solid #000;
                                        right: 10px solid transparent;
                                        left: 10px solid transparent;
                                        bottom: 10px solid transparent;
                                    }
                                }
                            }
                        }
                        &.kid {
                            label {
                                &:before {
                                    background: $blue;
                                }
                            }
                        }
                        &.parent {
                            label {
                                &:before {
                                    background: white;
                                }
                            }
                        }
                        &.address {
                            label {
                                &:before {
                                    background: $green;
                                }
                            }
                        }
                    }
                }
            }
            .left-side {
                background: $grey-2;
                padding: 120px 0;
                width: 70%;
                @include screen-md {
                    padding: gutter() 45px;
                }
            }

            .agrees {
                width: 100%;
                @extend .container;
                .heading-wrap {
                    width: span(1 of 12);
                    background: $blue;
                    @include flex(wrap, center, center);
                    margin-left: gutter();
                    padding: 20px;
                    @include screen-md {
                        width: span(12 of 12);
                        margin-left: 0;
                    }
                    h3 {
                        font: bold $h1;
                        writing-mode: vertical-rl;
                        @include screen-md {
                            writing-mode: horizontal-tb;
                            font: bold $h2;
                        }
                    }
                }
                .form-wrap {
                    padding: 40px;
                    width: span(11 of 12);
                    @include screen-md {
                        width: span(10 of 12);
                    }
                    .radio-wrap {
                        margin-bottom: gutter();
                        p.label {
                            position: relative;
                            &:before {
                                content: "";
                                @include position(absolute, 4px, $right: -20px);
                                width: 8px;
                                height: 8px;
                                background: $green;
                            }
                        } 
                        label {
                            font: $p2;
                            width: 465px;
                            max-width: 100%;

                        }
                        .notes-wrap {
                            label {
                                width: 100%;
                                display: block;
                            }
                            input {
                                width: 300px;
                                height: 35px;
                                max-width: 100%;
                                border: 1px solid #000;
                            }
                        }
                    }
                }
            }
            .submit-btn {
                margin: 20px auto;
            }
        }
        
    }
}