.lead-page {

    .over-link {
        @include position(absolute, 0, 0);
        width: 100%;
        height: 100%;
        display: block;
        z-index: 4;
    }

    .hero {
        height: 730px;
        background: url(../img/bg/lead-hero.png);
        background-position: center top;
        background-size: cover;
        position: relative;
        @include screen-md {
            max-height: 70vh;
        }
        h1.logo {
            @include position(absolute, $bottom: 10px, $left:20px);
            // @include translate(-50%, 0);
        }
    }

    section.goal {
        position: relative;
        .goal-img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: auto;
            pointer-events: none;
            z-index: 1;
            @include screen-md {
                // display: none;
                right: -40px;
            }
        }
        .top-section {
            @extend .container;
            width: 100%;
            background: $green;
            text-align: center;
            .content {
                width: span(8 of 12);
                margin-right: auto;
                position: relative;
                padding: 35px 0;
                @include screen-md {
                    padding: 10px 0 20px;
                }
                &:after {
                    content: "";
                    @include position(absolute, 100%, 50%);
                    transform: translate(-50%, -50%) rotate(45deg);
                    background: $green;
                    width: 70px;
                    height: 70px;
                    z-index: 1;
                    @include screen-md {
                        width: 40px;
                        height: 40px;
                    }
                }
                h2 {
                    font: bold 80px/1 $mainFont;
                    margin-bottom: 20px;
                    z-index: 2;
                    position: relative;
                    @include screen-md {
                        font: bold $h4;
                        margin-bottom: 0;
                    }
                }
                h3 {
                    font: bold 56px/1 $mainFont;
                    width: 750px;
                    max-width: 100%;
                    margin: 0 auto;
                    z-index: 3;
                    position: relative;
                    @include screen-md {
                        font-size: 18px;
                    }
                }
            }
        }
        .bottom-section {
            background: $yellow;
            text-align: center;
            padding: 70px 0 50px;
            position: relative;
            @include screen-md {
                padding: 30px 10px gutter();
            }
            .content {
                width: span(8 of 12);
                margin-right: auto;
                h3 {
                    font: bold 80px/1 $mainFont;
                    @include screen-md {
                        font: bold $h4;
                    }
                }
            }
        }
    }

    section.links {
        .contact {
            @extend .container;
            width: 100%;
            .heading-strip {
                @include flex(wrap);
                width: 100%;
                h2 {
                    text-align: center;
                    background: $grey-2;
                    font: bold 80px/1 $mainFont;
                    padding: 70px gutter();
                    width: span(6 of 12);
                    position: relative;
                    z-index: 2;
                    @include screen-md {
                        width: span(8 of 12);
                        font: 600 $h4;
                        padding: 35px 10px;
                    }
                    &:after {
                        content: "";
                        @include position(absolute, 100%, 50%);
                        transform: translate(-50%, -50%) rotate(45deg);
                        background: $grey-2;
                        width: 70px;
                        height: 70px;
                        z-index: 1;
                        @include screen-md {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }
            .contact-links {
                background: $blue;
                width: 100%;
                padding: 70px gutter();
                @include screen-md {
                    padding: 30px 10px gutter();
                }
                ul {
                    @include flex(wrap, center, center);
                    li {
                        position: relative;
                        padding: 35px 100px;
                        text-align: center;
                        @include screen-md {
                            padding: 15px;
                        }
                        &:after {
                            content: "";
                            display: block;
                            background: #FFF;
                            @include position(absolute, 50%, 0);
                            @include translate(0, -50%);
                            width: 2px;
                            height: 70%;
                        }
                        &:last-of-type {
                            &:after {
                                display: none;
                            }
                        }
                        a {
                            display: block;
                            img {
                                @include screen-md {
                                    max-width: 35px;
                                    max-height: 45px;
                                }
                            }
                            p {
                                font: 600 $p2;
                                color: #000;
                                @include screen-md {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .social-links {
            @extend .container;
            width: 100%;
            h2 {
                width: span(5 of 12);
                background: $grey-2;
                position: relative;
                font: bold 80px/1 $mainFont;
                text-align: center;
                @include flex(wrap, center, center);
                padding: 10px;
                @include screen-md {
                    // width: span(12 of 12);
                    font: 600 $h4;
                    // order: -99;
                }
                span {
                    z-index: 2;
                }
                &:after {
                    content: "";
                    @include position(absolute, 50%, 100%);
                    transform: translate(-50%, -50%) rotate(45deg);
                    background: $grey-2;
                    width: 100px;
                    height: 100px;
                    z-index: 1;
                    @include screen-md {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
            .links-wrap {
                width: span(7 of 12);
                padding: 80px;
                margin-left: gutter();
                @include screen-md {
                    padding: gutter() 10px;
                }
                ul {
                    @include flex(wrap, center, center);
                    height: 100%;
                    li {
                        padding: 35px;
                        position: relative;
                        @include screen-md {
                            padding: 12px;
                        }
                        &:after {
                            content: "";
                            display: block;
                            background: $green;
                            @include position(absolute, 50%, 0);
                            @include translate(0, -50%);
                            width: 2px;
                            height: 50%;
                        }
                        &:last-of-type {
                            &:after {
                                display: none;
                            }
                        }
                        a {
                            display: block;
                            img {
                                @include screen-md {
                                    max-width: 30px;
                                    max-height: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .strips {
        .strip-wrap {
            margin-bottom: gutter();
            .strip {
                background: $grey-1;
                display: block;
                padding: 35px 0;
                text-align: center;
                color: #FFF;
                font: 600 $h1;
                @include screen-md {
                    padding: 15px;
                }
                @include screen-md {
                    font-size: 20px;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            .strip-content {
                background: $grey-1;
                display: none;
                padding: 40px gutter();
                font: $p2;
                color: #FFF;
                h4, b, strong,  {
                    font: 600 $p1;
                }
                &.active {
                    display: block;
                }
                .divider {
                    width: 90%;
                    margin: gutter() auto;
                    height: 1px;
                    display: block;
                    background: #FFF;
                }
                .inner-content {
                    &.heb {
                        direction: rtl;
                    }
                    &.en {
                        direction: ltr;
                    }
                }
                .share-links {
                    ul {
                        @include flex(wrap, center, center);
                        li {
                            a {
                                display: block;
                                padding: 0 10px;
                                img {
                                    @include screen-md {
                                        max-width: 44px;
                                        max-height: 60px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    footer {
        background: $blue;
        padding: gutter();
        position: relative;
        @include screen-md {
            padding: 15px;
        }
        p {
            font: $h2;
            text-align: center;
            @include screen-md {
                font-size: 18px;
            }
        }
    }

}

section.join {
    @extend .container;
    width: 100%;
    .over-link {
        @include position(absolute, 0, 0);
        width: 100%;
        height: 100%;
        display: block;
        z-index: 4;
    }
    .col {
        @include flex(wrap, center, center);
        width: 33.333%;
        @include screen-md {
            // width: 100%;
            padding: gutter() 15px;
        }
        &:last-of-type {
            margin-left: 0;
        }
        &.yellow {
            background: $yellow;
            padding: 10px; // min-height: 22vw;
            position: relative;
            &:after {
                content: "";
                width: 80%;
                height: 100px;
                border: 1px solid #000;
                @include position(absolute, 50%, 50%);
                @include translate(-50%, -50%);
                z-index: 1;
                @include screen-md {
                    width: 90%;
                    height: 70px;
                }
            }
            .inner-block {
                @include flex(wrap, center, center);
                background: $yellow;
                width: 80%;
                height: 100%;
                z-index: 2;
                @include screen-md {
                    width: 100%;
                }
            }
            .inner {
                text-align: center;
                position: relative;
                h3 {
                    font: bold $h3;
                    @include screen-md {
                        font-size: 18px;
                    }
                }
                h4 {
                    font: 600 $h4;
                    @include screen-md {
                        font-size: 14px;
                    }
                }
            }
        }
        &.blue {
            @include flex(wrap, center, center);
            background: $blue;
            text-align: center;
            position: relative;
            @include screen-md {
                padding: 10px;
            }
            h2 {
                font: bold $h1;
                width: 300px;
                margin: 0 auto;
                max-width: 100%;
                @include screen-md {
                    font-size: 18px;
                }
            }
        }
    }
}