.inner.practice {
    section.hero {
        .hero-img {
            // height: 550px;
            background: url(../img/bg/practice.jpg);
            // max-height: 40vh;
            background-size: cover;
            background-position-x: 50%;
            background-position-y: 50%;
        }
    }
    section.main-content {
        .heading-box {
            .quote {
                @include screen-md {
                    display: none;
                }
            }
        }
        .blue-bg {
            .heading-box {
                .quote {
                    background: $blue;
                    z-index: 2;
                }
                h3 {
                    @include screen-md {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
        .green-bg {
            .heading-box {
                width: span(5 of 12);
                @include screen-md {
                    width: span(12 of 12);
                }
                h3 {
                    width: 75%;
                    text-align: right;
                    z-index: 3;
                    min-height: 100%;
                    font: bold $h2;
                    box-sizing: border-box;
                    @include screen-md {
                        font-size: 30px;
                    }
                }

                &:after {
                    height: 80%;
                    width: 90%;
                }
            }
            .white-bullets {
                padding: 40px;
                width: span(7 of 12);
                @include screen-md {
                    width: span(12 of 12);
                }
            }
        }
        .grey-bg {
            padding: 0;
            > p {
                padding: gutter()*2;
                font: $p1;
                width: span(10 of 12);
                max-width: 1000px;
                @include screen-md {
                    width: span(12 of 12);
                }
            }
            .heading-box {
                padding: gutter() 0;
                margin-left: gutter();
                &.side-heading {
                    width: span(2 of 12) !important;
                    background-color: #fff;
                    @include screen-md {
                        width: span(12 of 12) !important;
                        margin-left: 0;
                        text-align: center;
                    }
                    h3 {
                        writing-mode: vertical-rl;
                        width: auto;
                        text-align: center;
                        @include screen-md {
                            writing-mode: horizontal-tb;
                        }
                    }
                    .quote {
                        width: 44px;
                        &.upquote {
                            @include position(absolute, 20px, $right: 20px);
                        }
                        &.downquote {
                            @include position(absolute, $bottom: 20px, $left: 20px);
                        }
                    }
                }
            }
            .content {
                width: span(10 of 12);
                padding: 40px;
                @include screen-md {
                    width: span(12 of 12);
                    padding: 40px gutter();
                }
                p {
                    font: $p1;
                }   
            }
        }
    }
}