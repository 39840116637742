$susy: (
	'columns': susy-repeat(12),
	'gutters': 20px,
	'spread': 'narrow',
	'container-spread': 'narrow',
);

.container {
    @include flex(wrap);
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    &.full {
        width: 100%;
    }
    .half-w {
        width: span(6 of 12);
        margin-left: gutter();
        &:nth-child(2n) {
            margin-left: 0;
        }
    }
}
