.inner.about {
    section.hero {
        .hero-img {
            background: url(../img/bg/practice.jpg);
            background-size: cover;
            background-position-x: 50%;
            background-position-y: 50%;
        }
    }
    section.main-content {
        .team-wrap {
            .team-member {
                font: $p1;
                .title {
                    width: 100%;
                    padding: 15px 0;
                    text-align: center;
                    background: $blue;
                    h3 {
                        font: bold $h3;
                    }
                }
                .member-content {
                    @extend .container;
                    width: 100%;
                    .img-wrap {
                        width: span(3 of 12);
                        margin-left: gutter();
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: top center;
                            max-height: 600px;
                            @include screen-md {
                                max-height: 400px;
                            }
                        }
                        @include screen-md {
                            width: span(12 of 12);
                            margin-left: 0;
                        }
                    }
                    .content {
                        width: span(9 of 12);
                        padding: 40px 0;
                        .description {
                            // width: 310px;
                            max-width: 100%;
                            padding: 0 gutter();
                            margin: 0 auto ;
                        }
                        blockquote {
                            padding: gutter();
                            // border: 1px solid #000;
                            font-weight: 600;
                            font-style: italic;
                            // width: 700px;
                            max-width: 100%;
                            margin: 0 auto 0;
                        }
                    }
                }
                &.founder {
                    background: $grey-2;
                    @include screen-md {
                        margin-bottom: gutter();
                    }
                    .member-content {
                        padding-left: gutter();
                        // blockquote {
                        //     border: none;
                        // }
                        @include screen-md {
                            padding-left: 0;
                        }
                        .content {
                            padding: gutter()*2 0;
                            margin: 0 auto;
                        }
                    }
                }
                &:not(.founder) {
                    .title {
                        margin-right: - gutter();
                        margin-bottom: 40px;
                        width: calc(100% + #{gutter()});
                        @include screen-md {
                            margin: 0 auto;
                            width: 100%;
                        }
                    }
                    .content {
                        padding: 0 0 40px;
                        @include screen-md {
                            width: span(12 of 12);
                        }
                    }
                }
                &:nth-of-type(2n) {
                    .img-wrap {
                        @include screen-xl {
                            order: 99;
                            margin-left: 0;
                            margin-right: gutter();
                        }
                    }
                    &:not(.founder) {
                        .title {
                            margin-right: 0;
                            margin-left: - gutter();
                            @include screen-md {
                                margin: 0 auto;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        
    }
}