.inner.gallery {
    section.hero {
        .hero-img {
            height: 550px;
            background: url(../img/bg/pics-hero.png);
            // max-height: 40vh;
            background-size: cover;
            background-position-x: 50%;
            background-position-y: 50%;
        }
    }
    section.main-content {
        
        .gallery-wrap {
            .top-section {
                background: $blue;
                padding: 40px gutter();
                .heading-wrap {
                    @include flex(wrap, flex-start, center);
                    width: span(6 of 12);
                    @include screen-md {
                        width: span(12 of 12);
                    }
                    .heading-box {
                        @include flex(wrap, center, center);                        
                        font: bold $h1;
                        width: 320px;
                        height: 260px;
                        border: 1px solid #000;
                        text-align: center;
                        position: relative;
                        @include screen-md {
                            font: bold $h2;
                            width: 100%;
                            height: 160px;
                        }
                        span {
                            display: block;
                        }
                        &:after {
                            content: "";
                            width: 70px;
                            height: 20px;
                            background: $blue;
                            @include position(absolute, 0, $right: 40px);
                            @include translate(0, -50%);
                        }
                        &:before {
                            content: "";
                            width: 70px;
                            height: 20px;
                            background: $blue;
                            @include position(absolute, 100%, $left: 40px);
                            @include translate(0, -50%);
                        }
                    }
                }
                .img-wrap {
                    width: span(6 of 12);
                    @include screen-md {
                        padding-top: 60px;
                        width: span(12 of 12);
                    }
                    img {
                        width: 480px;
                        margin: 0 auto;
                        max-height: 350px;
                        object-fit: contain;
                    }
                }
            }
            .bottom-section {
                background: $green;
                padding: 40px 0;
                .gallery-slider {
                    position: relative;
                    .owl-item {
                        &.active.center {
                            img {
                                border: 2px solid #000;
                            }
                        }
                        img {
                            max-height: 300px;
                            object-fit: contain;
                        }
                    }
                    .owl-nav {
                        @include position(absolute, 50%, 0);
                        width: 100%;
                        pointer-events: none;
                        >button {
                            pointer-events: all;
                            text-indent: -999px;
                            width: 40px;
                            height: 30px;
                            overflow: hidden;
                            &.owl-prev {
                                @include position(absolute, 50%, 102%);
                                border: {
                                    left: 30px solid $grey-2;
                                    top: 20px solid transparent;
                                    bottom: 20px solid transparent;
                                    right: 20px solid transparent;
                                }
                                @include screen-md {
                                    left: calc(100% - 20px);
                                    border-width: 15px;
                                }
                            }
                            &.owl-next {
                                @include position(absolute, 50%, $right: 102%);
                                border: {
                                    right: 30px solid $grey-2;
                                    top: 20px solid transparent;
                                    bottom: 20px solid transparent;
                                    left: 20px solid transparent;
                                }
                                @include screen-md {
                                    right: calc(100% - 20px);
                                    border-width: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .video-gallery {
            @extend .container;
            width: 100%;
            .heading-wrap {
                background: #FFF;
                width: span(2 of 12);
                margin-left: gutter();
                padding: 40px;
                @include flex(wrap, center, center);
                @include screen-md {
                    width: span(12 of 12);
                    padding: 20px;
                    margin-left: 0;
                }
                h2 {
                    writing-mode: vertical-rl;
                    font: bold $h1;
                    text-align: center;
                    @include screen-md {
                        writing-mode: horizontal-tb;
                        font: bold $h2;
                    }
                }
            }
            .video-grid {
                @extend .container;
                width: span(10 of 12);
                background: $grey-2;
                padding: 40px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                @include screen-md {
                    width: span(12 of 12);
                    padding: 20px 20px (20px - gutter());
                }
                .owl-nav {
                    @include position(absolute, 50%, 0);
                    @include translate(0, -50%);
                    width: 100%;
                    pointer-events: none;
                    >button {
                        pointer-events: all;
                        text-indent: -999px;
                        width: 40px;
                        height: 30px;
                        overflow: hidden;
                        @include translate(0, -50%);
                        &.owl-prev {
                            @include position(absolute, 50%, calc(100% - 40px));
                            border: {
                                left: 30px solid #FFF;
                                top: 20px solid transparent;
                                bottom: 20px solid transparent;
                                right: 20px solid transparent;
                            }
                        }
                        &.owl-next {
                            @include position(absolute, 50%, $right: calc(100% - 40px));
                            border: {
                                right: 30px solid #FFF;
                                top: 20px solid transparent;
                                bottom: 20px solid transparent;
                                left: 20px solid transparent;
                            }
                        }
                    }
                }
                .item {
                    // width: span(4 of 12);
                    margin-left: gutter();
                    // margin-bottom: gutter();
                    position: relative;
                    cursor: pointer;
                    .over-link {
                        @include position(absolute, 0, 0);
                        width: 100%;
                        height: 100%;
                        @include flex(wrap, center, center);
                        pointer-events: none;
                        background: rgba(#000, 0.6);
                        opacity: 0;
                        transition: all 300ms ease-in-out;
                        z-index: 4;
                        h4 {
                            color: #FFF;
                        }
                    }
                    &:hover {
                        .over-link {
                            opacity: 1;
                        }
                    }
                    .img-wrap {
                        position: relative;
                        width: 100%;
                        padding-top: 56.6%;
                        img {
                            @include position(absolute, 0, 0);
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    h4 {
                        text-align: center;
                        margin-top: 10px;
                        font: 600 $p1;
                    }
                    // &:nth-of-type(3n) {
                    //     margin-left: 0;
                    // }
                    @include screen-md {
                        // width: span(6 of 12);
                        // &:nth-of-type(3n) {
                        //     margin-left: gutter();
                        // }
                        // &:nth-of-type(2n) {
                        //     margin-left: 0;
                        // }
                    }
                }
            }
        }
        
    }

    section.join {
        @extend .container;
        width: 100%;
        .col {
            @include flex(wrap, center, center);
            width: 33.333%;
            @include screen-md {
                // width: 100%;
                padding: gutter() 15px;
            }
            &:last-of-type {
                margin-left: 0;
            }
            &.yellow {
                background: $yellow;
                padding: 10px; // min-height: 22vw;
                position: relative;
                &:after {
                    content: "";
                    width: 80%;
                    height: 100px;
                    border: 1px solid #000;
                    @include position(absolute, 50%, 50%);
                    @include translate(-50%, -50%);
                    z-index: 1;
                    @include screen-md {
                        width: 90%;
                        height: 70px;
                    }
                }
                .inner-block {
                    @include flex(wrap, center, center);
                    background: $yellow;
                    width: 80%;
                    height: 100%;
                    z-index: 2;
                    @include screen-md {
                        width: 100%;
                    }
                }
                .inner {
                    text-align: center;
                    position: relative;
                    h3 {
                        font: bold $h3;
                        @include screen-md {
                            font-size: 18px;
                        }
                    }
                    h4 {
                        font: 600 $h4;
                        @include screen-md {
                            font-size: 14px;
                        }
                    }
                }
            }
            &.blue {
                @include flex(wrap, center, center);
                background: $blue;
                text-align: center;
                @include screen-md {
                    padding: 10px;
                }
                h2 {
                    font: bold $h1;
                    width: 300px;
                    margin: 0 auto;
                    max-width: 100%;
                    @include screen-md {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .video-popup {
        @include position(fixed, 50%, 50%);
        width: 1200px;
        max-width: 90vw;
        height: 800px;
        max-height: 90vh;
        padding: 60px;
        background: #FFF;
        @include translate(-50%, -50%);
        z-index: 999999;
        opacity: 0;
        pointer-events: none;
        transition: all 350ms ease-in-out;
        @include screen-md {
            height: 67vw;
            padding: 0;
        }
        &.active {
            opacity: 1;
            pointer-events: all;
        }
        .video-frame {
            width: 100%;
            height: 100%;
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
    .video-popup-overlay {
        @include position(fixed, 0, 0);
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.6);
        z-index: 999998;
        opacity: 0;
        pointer-events: none;
        transition: all 350ms ease-in-out;
        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }
}