@import url('https://fonts.googleapis.com/css?family=Assistant:300,500,600,700');

@import "node_modules/susy/sass/susy";
@import 'lib/reset';
@import 'lib/owl/owl.carousel';
@import 'lib/lightbox';

@import 'vars';
@import 'mixins';
@import 'screens';
@import 'grid';

@import 'glob';
@import 'header';
@import 'footer';

@import 'home';
@import 'inner';
@import 'trial-terms';
@import 'terms';
@import 'practice';
@import 'why-us';
@import 'testimonials';
@import 'about';
@import 'reg';
@import 'gallery';
@import 'blog';
@import 'blog-inner';
@import 'lead';