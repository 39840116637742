header {
    padding-top: gutter();
    height: 120px;
    background: #FFF;
    z-index: 9999;
    @include position(fixed, 0, 0);
    width: 100%;
    .site-logo {
        @include position(absolute, 0, 50%);
        @include translate(-50%, 0);
        z-index: 9999;
        a {
            display: block;
            img {
                height: 120px;
            }
        }
    }
    .header-main {
        position: relative;
        z-index: 999;
        border: {
            top: 1px solid #000;
            bottom: 1px solid #000;
        }
        height: 90px;
        .menu-button {
            @include position(absolute, 50%, $right: 30px);
            @include translate(0, -50%);
            @include screen-md {
                right: 20px;
                height: 40px;
                display: flex;
            }
            button {
                @include flex(nowrap, center, center);
                appearance: none;
                background: transparent;
                border: none;
                cursor: pointer;
                font: $p1;
                outline: none !important;
                .burger {
                    position: relative;
                    display: block;
                    width: 50px;
                    height: 7px;
                    background: $green-2;
                    margin-right: 15px;
                    transition: all $long;
                    @include screen-md {
                        margin-right: 0;
                        width: 30px;
                        height: 4px;
                    }
                    &:before {
                        content: "";
                        @include position(absolute, -13px, 0);
                        display: block;
                        width: 50px;
                        height: 7px;
                        background: $green-2;
                        transition: all $long;
                        @include screen-md {
                            width: 30px;
                            height: 4px;
                            top: -10px;
                        }
                    }
                    &:after {
                        content: "";
                        @include position(absolute, 13px, 0);
                        display: block;
                        width: 50px;
                        height: 7px;
                        background: $green-2;
                        transition: all $long;
                        @include screen-md {
                            width: 30px;
                            height: 4px;
                            top: 10px;
                        }
                    }
                }
                .text {
                    display: block;
                    line-height: 1;
                    @include screen-md {
                        display: none;
                    }
                }
            }

            &.active {
                .burger {
                    background: transparent;
                    &:before {
                        @include position(absolute, 0, 0);
                        transform: rotate(45deg);
                    }
                    &:after {
                        @include position(absolute, 0, 0);
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        .call-btn {
            cursor: pointer;
            @include flex(wrap, center, center);
            display: none;
            width: 30px;
            height: 30px;
            border: 1px solid $green;
            border-radius: 50%;
            @include position(absolute, 50%, 60px);
            @include translate(0, -50%);
            img {
                width: 16px;
                transform: rotate(0);
                transition: all 300ms ease-in-out;
            }
            @include screen-md {
                display: flex;
            }
        }
        .top-social {
            @include position(absolute, 50%, 30px);
            @include translate(0, -50%);
            @include screen-md {
                left: 20px;
            }
            .drop-btn {
                cursor: pointer;
                @include flex(wrap, center, center);
                display: none;
                width: 30px;
                height: 30px;
                border: 1px solid $green;
                border-radius: 50%;
                img {
                    width: 16px;
                    transform: rotate(0);
                    transition: all 300ms ease-in-out;
                }
                &.active {
                    img {
                        transform: rotate(540deg);
                    }
                }
                @include screen-md {
                    display: flex;
                }
            }
            ul {
                @include flex(wrap, center, center, row-reverse);
                @include screen-md {
                    padding: 10px 0;
                    display: none;
                    flex-direction: column;
                    background: #FFF;
                    box-shadow: 1px 1px 10px -3px rgba(#000, 0.3);
                    margin-top: 10px;
                    @include position(absolute, 100%, 50%);
                    @include translate(-50%, 0);
                }
                li {
                    padding: 0 15px;
                    position: relative;
                    @include screen-md {
                        padding: 10px;
                        text-align: center;
                        a {
                            display: block;
                            width: 35px;
                        }
                    }
                    &:after {
                        content: "";
                        @include position(absolute, 50%, $right: 0);
                        @include translate(0, -50%);
                        width: 1px;
                        height: 30px;
                        background: $green-2;
                        display: block;
                        @include screen-md {
                            top: 100%;
                            left: 0;
                            @include translate(0, 0);
                            width: 100%;
                            height: 1px;
                            display: none;
                        }
                    }
                    &:last-child {
                        padding-right: 0;
                        @include screen-md {
                            padding: 10px;
                        }
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .site-nav {
        @include position(fixed, 0, $right: 0);
        @include translate(100%, 0);
        height: 100%;
        width: 450px;
        max-width: 100%;
        background: #333;
        z-index: 999999;
        overflow-x: hidden;
        overflow-y: auto;
        transition: transform $long;
        &.active {
            @include translate(0, 0);
        }
        @include screen-md {
            width: 100%;
        }
        .close-button {
            @include position(absolute, 35px, $right: 40px);
            height: 50px;
            width: 50px;
            cursor: pointer;
            .burger {
                @include position(absolute, 50%, 50%);
                @include translate(-50%, -50%);
                display: block;
                width: 50px;
                height: 7px;
                background: transparent;
                margin-right: 15px;
                transition: all $long;
                &:before {
                    content: "";
                    @include position(absolute, 0, 0);
                    transform: rotate(45deg);
                    display: block;
                    width: 50px;
                    height: 7px;
                    background: $green-2;
                    transition: all $long;
                }
                &:after {
                    content: "";
                    @include position(absolute, 0, 0);
                    transform: rotate(-45deg);
                    display: block;
                    width: 50px;
                    height: 7px;
                    background: $green-2;
                    transition: all $long;
                }
            }
        }
        ul {
            @include flex(wrap, center, center, column);
            height: 100%;
            width: 100%;
            min-height: 500px;
            li {
                width: 100%;
                text-align: center;
                a {
                    color: #FFF !important;
                    font: $h4;
                    font-size: 26px;
                }
            }
        }
    }
}