footer {
    background: #464947;
    padding: 60px gutter();
    overflow: hidden;
    position: relative;
    .container {
        .footer-social {
            width: 100%;
            ul {
                @include flex(wrap, flex-start);
                padding: 0 10px;
                @include screen-md {
                    justify-content: center;
                    padding: 0;
                }
                a {
                    display: block;
                    margin: 0 10px;
                }
            }
        }
        .col {
            width: span(3 of 12);
            margin-left: gutter();
            padding: gutter();
            &:last-child {
                margin-left: 0;
            }
            @include screen-md {
                width: span(12 of 12);
            }
            h5 {
                font: 600 $p1;
                color: #FFF;
                margin-bottom: 30px;
            }
            > p {
                color: #FFF;
                font: 200 $p1;
            }
            .tags {
                @include flex(wrap, space-between);
                .tag {
                    display: block;
                    padding: 3px 5px;
                    background: $blue;
                    min-width: 26%;
                    text-align: center;
                    margin-bottom: 5px;
                }
            }
            .news {
                .item {
                    width: 100%;
                    @include flex(wrap);
                    margin-bottom: 30px;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #FFF;
                    .date {
                        width: 50px;
                        height: 50px;
                        border: 2px solid $green-2;
                        @include flex(wrap, center, center);
                        color: #FFF;
                        font: 500 16px/1.2 $mainFont;
                        .day, .month {
                            display: block;
                            width: 100%;
                            text-align: center;
                        }
                    }
                    .content {
                        width: calc(100% - 50px);
                        padding-right: 5px;
                        color: #FFF;
                        font: 300 18px/1.2 $mainFont;
                    }
                }
            }
            .footer-newsletter {
                #mc_embed_signup_scroll {
                    @include flex(wrap, center, center);
                    width: 100%;
                }
                form {
                    input {
                        padding: 0 10px;
                        margin-top: gutter();
                        width: 100%;
                        border-radius: 30px;
                        appearance: none;
                        border: none;
                        background: #FFF;
                        height: 40px;
                        font: $p1;
                    }
                    .submit {
                        padding: $blue;
                        border-radius: 30px;
                        appearance: none;
                        border: none;
                        background: $blue;
                        width: 150px;
                        height: 40px;
                        font: bold $h5;
                        coloe: $grey-1;
                    }
                }
            }
        }
    }
}