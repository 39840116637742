$mobile-breakpoint: 600px;
$tablet-breakpoint: 900px;
$desktop-breakpoint: 1200px;
@mixin screen-xs {
    @media screen and (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin screen-md {
    @media screen and (max-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin screen-xl {
    @media screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin mobileonly($display-xs: block, $display-xl: none) {
    display: $display-xl !important;
    @include screen-xs {
        display: $display-xs !important;
    }
}

@mixin desktoponly($display-xl: block, $display-xs: none) {
    display: $display-xl !important;
    @include screen-xs {
        display: $display-xs !important;
    }
}