.inner.blog {
    section.hero {

    }
    section.main-content {
        .blue-bg {
            padding: 40px gutter();
            .heading-box {
                padding: 0 gutter() gutter()*2;
                width: span(3 of 12);
                margin-right: gutter();
                @include screen-md {
                    width: span(12 of 12);
                    margin-bottom: gutter();
                    margin-right: 0;
                }
                .heading-content {
                    position: relative;
                    min-height: 60%;
                    @include flex(wrap, center, center);     
                    padding: 60px;
                    h3 {
                        width: fit-content;
                        text-align: center;
                    }
                }
                .author {
                    @include flex(wrap, center, center);
                    @include position(absolute, $bottom: 0px, $left: 50%);
                    @include translate(-50%, 0);
                    width: 100%;
                    .divider {
                        font: $p2;
                        display: block;
                        margin: 0 10px;
                    }
                    h4 {
                        font: $p2;
                    }
                }
                .quote {
                    width: 51px;
                    left: 50%;
                    right: auto;
                    @include translate(-50%, 0);
                    &.downquote {
                        bottom: auto;
                        bottom: 0;
                    }
                    &.upquote {
                        top: 0;
                    }
                }
                &:after {
                    display: none;
                }
            }
            .post-content {
                width: span(9 of 12);
                @include flex(wrap);
                @include screen-md {
                    width: span(12 of 12);
                }
                .content-wrap {
                    width: 100%;
                    max-width: 100%;
                    padding: gutter();
                    display: flex;
                    align-items: center;
                    .content-inner {
                        padding: gutter();
                        width: 100%;
                        position: relative;
                        border: 1px solid #000;
                        &:after {
                            content: "";
                            @include position(absolute, 0, $right: 20%);
                            @include translate(0, -50%);
                            height: 10px;
                            width: 40%;
                            background: $blue;
                            z-index: 1;
                        }

                        &:before {
                            content: "";
                            @include position(absolute, 100%, 20%);
                            @include translate(0, -50%);
                            height: 10px;
                            width: 40%;
                            background: $blue;
                            z-index: 1;
                        }
                    }
                    p {
                        font: $p1;
                        position: relative;
                        z-index: 2;
                        width: 780px;
                        max-width: 100%;
                    }
                    .button-wrap {
                        position: relative;
                        z-index: 2;
                        margin-top: gutter();
                        @include flex(wrap, flex-end);
                        @include screen-md {
                            justify-content: center;
                        }
                        .button {
                            min-width: 240px;
                            min-height: 60px;
                            font-size: 24px;
                            @include screen-md {
                                min-width: 190px;
                            }
                        }
                    }
                }
            }
        }
        .posts-slider-section {
            padding: gutter() 60px;
            @include screen-md {
                padding: gutter() 40px;
            }
            .posts-slider {
                .item {
                    border: 2px solid #000;
                    position: relative;
                    padding: gutter() 40px;
                    width: 320px;
                    margin: 0 auto;
                    max-width: 100%;
                    position: relative;
                    transition: all 300ms ease-in-out;
                    &:after {
                        content: "";
                        @include position(absolute, 20%, 0);
                        @include translate(-50%, 0);
                        height: 40%;
                        width: 10px;
                        background: $grey-2;
                        z-index: 1;
                    }
                    &:before {
                        content: "";
                        @include position(absolute, $bottom: 20%, $left: 100%);
                        @include translate(-50%, 0);
                        height: 40%;
                        width: 10px;
                        background: $grey-2;
                        z-index: 1;
                    }
                    .over-link {
                        @include position(absolute, 0, 0);
                        width: 100%;
                        height: 100%;
                        display: block;
                        z-index: 4;
                    }
                    .item-inner {
                        z-index: 2;
                        position: relative;
                        h3 {
                            font: 600 $h3;
                            text-align: center;
                            margin-bottom: gutter();
                        }
                        .img-wrap {
                            margin-bottom: gutter();
                        }
                        .content-wrap {
                            .inner-content {
                                p {
                                    font: $p1;
                                }
                            }
                        }
                        .author {
                            @include flex(wrap, center, center);
                            width: 100%;
                            .divider {
                                font: $p1;
                                display: block;
                                margin: 0 10px;
                            }
                            h4 {
                                font: $p2;
                            }
                        }
                    }
                    .button {
                        margin: gutter() auto;
                        min-width: 200px;
                        min-height: 40px;
                        font-size: 22px;
                        @include screen-md {
                            min-width: 95%;
                            max-width: 100%;
                        }
                    }
                }
                .owl-nav {
                    @include position(absolute, 50%, 0);
                    width: 100%;
                    pointer-events: none;
                    >button {
                        pointer-events: all;
                        text-indent: -999px;
                        width: 40px;
                        height: 30px;
                        overflow: hidden;
                        background: $grey-2;
                        &.owl-prev {
                            @include position(absolute, 50%, calc(100% + 10px));
                            border: {
                                left: 30px solid $blue;
                                top: 20px solid transparent;
                                bottom: 20px solid transparent;
                                right: 20px solid transparent;
                            }
                        }
                        &.owl-next {
                            @include position(absolute, 50%, $right: calc(100% + 10px));
                            border: {
                                right: 30px solid $blue;
                                top: 20px solid transparent;
                                bottom: 20px solid transparent;
                                left: 20px solid transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}