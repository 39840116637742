.inner.why {
    section.hero {
        .hero-img {
            height: 550px;
            background: url(../img/bg/practice.jpg);
            // max-height: 40vh;
            background-size: cover;
            background-position-x: 50%;
            background-position-y: 50%;
        }
    }
    section.main-content {
        .heading-box {
            .quote {
                @include screen-md {
                    display: none;
                }
            }
        }
        .green-bg {
            .heading-box {
                @include flex(wrap, center, center);
                padding: gutter();
                &:after {
                    display: none;
                }
                .heading-wrap {
                    @include flex(wrap, center, center);                    
                    width: 290px;
                    margin: gutter() auto;
                    z-index: 3;
                    height: 90%;
                    border: 1px solid #000;
                    position: relative;
                    .quote {
                        width: 44px;
                        background: #FFF;
                        @include screen-md {
                            display: block;
                        }
                        &.upquote {
                            @include position(absolute, 0, 75%);
                            @include translate(-50%, -50%);
                        }
                        &.downquote {
                            @include position(absolute, 100%, 25%);
                            @include translate(0, -50%);
                        }
                    }
                    h3 {
                        text-align: right;
                        background: transparent;
                    }
                }

            }
        }

        .blue-bg {
            .heading-box {
                width: span(5 of 12);
                // min-height: 250px;
                padding: 40px 20px;
                @include screen-md {
                    min-height: auto;
                    width: span(12 of 12);
                    padding: gutter() 0;
                }
                h3 {
                    width: 100%;
                    text-align: center;
                }
                &:after {
                    display: none;
                }
            }
            .content {
                width: span(7 of 12);
                @include flex(wrap, center, center);
                @include screen-md {
                    width: span(12 of 12);
                }
                p {
                    font: $p1;
                }
            }
        }

        .grey-bg {
            padding: 0;
            .heading-box {
                padding: 60px gutter();
                margin-left: gutter();
                width: span(3 of 12) !important;
                background-color: #fff;
                @include screen-md {
                    width: span(12 of 12) !important;
                    margin-left: 0;
                    text-align: center;
                }
                .quote {
                    width: 44px;
                    background: #FFF;
                    &.upquote {
                        @include position(absolute, 20px, 75%);
                        @include translate(0, 0);
                    }
                    &.downquote {
                        @include position(absolute, $bottom: 20px, $left: 25%);
                        @include translate(0, 0);                        
                    }
                }
                h3 {
                    width: auto;
                    text-align: center;
                }
            }
            .content {
                padding: gutter()*2;
                width: span(9 of 12);
                @include flex(wrap, center, center);
                @include screen-md {
                    width: span(12 of 12);
                }
                >p {
                    width: 100%;
                    font: $p1;
                    max-width: 1000px;
                }
            }
        }
    }
}