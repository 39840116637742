$headerHeight: 120px;

//
// COLORS
//
$green: #D1DE71;
$green-2: #B5CF6C;
$blue: #C9E4E9;
$yellow: #FBF038;
$grey-1: #575757;
$grey-2: #E6E6E8;

//
// FONTS
//
$mainFont: 'Assistant', Arial, Helvetica, sans-serif;
$h1: 50px/1.4 $mainFont;
$h2: 42px/1.4 $mainFont;
$h3: 36px/1.4 $mainFont;
$h4: 32px/1.4 $mainFont;
$h5: 27px/1.4 $mainFont;

$p1: 22px/1.4 $mainFont;
$p2: 18px/1.4 $mainFont;

$btn-typo: 32px/1 $mainFont;

//
// ANIMATION
//
$easeInOut: cubic-bezier(0.86, 0, 0.07, 1);
$long: 500ms $easeInOut;