.inner.terms {
    section.hero {

    }
    section.main-content {
        .green-bg {
            .button {
                margin: 60px auto 0;
            }
        }
        .grey-bg {
            padding: 0;
            > p {
                padding: gutter()*2;
                font: $p1;
                width: span(11 of 12);
                max-width: 1000px;
            }
            .heading-box {
                padding: gutter() 0;
                margin-left: gutter();
                width: span(4 of 12);
                @include screen-md {
                    width: span(12 of 12) !important;
                }
                &.side-heading {
                    width: span(1 of 12) !important;
                    background-color: #fff;
                    h3 {
                        writing-mode: vertical-rl;
                        width: auto;
                    }
                }
            }
            .blue-bullets {
                width: span(8 of 12);
                margin-left: 0;
                @include screen-md {
                    width: span(12 of 12);
                }
            }
        }
    }
}