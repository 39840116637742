::selection {
	background: $blue;
	color: #FFF;
}

body {
	direction: rtl;
	font-family: $mainFont;
	main {
		overflow-x: hidden;
        width: 100vw;
		padding-top: 140px;
		position: relative;
		@include screen-md {
			padding-top: 130px;
		}
	}
}

a {
	text-decoration: none !important;
}

img {
	max-width: 100%;
}

.button {
	appearance: none;
	border: none;
	@include flex(wrap, center, center);
	min-width: 300px;
	min-height: 80px;
	width: fit-content;
	border-radius: 15px;
	font: $btn-typo;
	&.square {
		border-radius: 0;
	}
	&.white {
		background: #FFF;
		color: #000;
	}
	&.blue {
		background: $blue;
		color: #000;
	}
	&.green {
		background: $green;
		color: #000;
	}
	&.yellow {
		background: $yellow;
		color: #000;
	}
}

form {
	width: 100%;
	direction: rtl;
	@extend .container;
	.input-wrap {
		width: 100%;
		input,
		textarea {
			appearance: none;
			background: #FFF;
			width: 100%;
			margin-bottom: 30px;
			height: 55px;
			border: none;
			border-radius: 15px;
			padding: 5px 10px;
			font: 300 $h5;
			&::placeholder {
				font: 300 $h5;
				color: $grey-1;
			}
		}
		textarea {
			min-height: 140px;        
		}
		&.two-inputs {
			@include flex(wrap, space-between, center);
			input {
				width: span(6 of 12);
			}
		}
	}
	.submit {
		margin: 30px auto;
	}

	.wpcf7-response-output {
		width: 100%;
	}
	> p {
		width: 100%;
	}
	.submit-btn {
		margin: 20px 0;
		@include screen-md {
			width: 100%;
			min-width: auto;
		}
	}

}

.breadcrumbs {
	font: 200 $p1;
	margin-bottom: 15px;
	padding-right: gutter();
	ul {
		@include flex(wrap);
		li {
			position: relative;
			margin-left: 15px;
			padding-left: 15px;
			&:after {
				content: "/";
				@include position(absolute, 50%, 0);
				@include translate(0, -50%);
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
			a {
				color: $grey-1 !important;
			}
		}
	}
}

.parallax-slider {
	height: 100%;
	.item {
		min-height: 56vw;
		max-height: 100vh;
		background-size: cover;
		background-position: 50% 50%;
		@include screen-md {
			height: 100vh;
		}
	}
	img {
		min-height: 56vw;
		max-height: 100vh;
	}
}