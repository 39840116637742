.inner.blog-single {
    section.hero {
        .hero-img {
            background-size: cover;
            background-position-x: 50%;
            background-position-y: 50%;
        }
        .heading-strip {
            width: 100%;
            padding: 30px 0;
            @include flex(wrap, center);
            h2, h3, .divider {
                color: $grey-1;
                font: bold $h1;
                text-align: center;
                display: block;
                margin: 0 10px;
            }
            @include screen-md {
                h2, h3, .divider {
                    width: 100%;
                }
                .title {
                    font: bold $h2;
                }
                .author {
                    font: bold $h3;
                }
                .date {
                    font: bold $h4;
                }
                .divider {
                    display: none;
                }
            }
        }
    }
    section.main-content {
        
        
    }
}