.inner {

    // main {
    //     padding-top: 170px;
    // }

    section.hero {
        position: relative;
        // margin-bottom: 30px;
        .hero-img {
            height: 700px;
            background: url(../img/bg/ex-1.jpg);
            // max-height: 40vh;
            background-size: cover;
            background-repeat: no-repeat;
            background-position-x: 50%;
            background-position-y: 50%;
            @include screen-md {
                height: 360px;
            }
            @include screen-xs {
                height: 240px;
            }
        }
        .heading-strip {
            width: 100%;
            padding: 30px 0;
            h2 {
                color: $grey-1;
                font: bold $h1;
                text-align: center;
            }
        }
    }

    section.main-content {
        .strip-h {
            font: bold $h5;
            color: #000;
            background: $green;
            padding: 5px 15px;
            width: 100%;
        }
    }
}

    section.main-content {
        > p,
        .intro-p p {
            padding: 0 20px 40px;
            font: $p1;
            max-width: 1200px;
            @include screen-md {
                padding: 20px;
            }
        }
        .blue-bg {
            background: $blue;
            padding: gutter();
            .heading-box {
                width: span(3 of 12);
                @include flex(wrap, center, center);
                position: relative;
                @include screen-md {
                    width: span(12 of 12);
                }
                h3 {
                    font: bold $h1;
                    width: 130px;
                    position: relative;
                    @include screen-md {
                        width: auto;
                    }
                    
                }
                &:after {
                    content: "";
                    display: block;
                    width: 255px;
                    height: 265px;
                    @include position(absolute, 50%, 50%);
                    @include translate(-50%, -50%);
                    border: 1px solid #fff;
                    @include screen-md {
                        display: none;
                    }
                    
                }
                .quote {
                    @include position(absolute);
                    // @include translate(-50%)
                    background: $blue;
                    padding: 5px;
                    &.upquote {
                        top: 0;
                        right: 25%;
                    }
                    &.downquote {
                        bottom: -10px;
                        left: 25%;
                    }
                }
            }
        }
        .green-bg {
            background: $green;
            @include screen-md {
                @include flex(wrap);
            }
            .heading-box {
                width: span(4 of 12);
                @include flex(wrap, center, center);
                position: relative;
                background-color: #FFF;
                position: relative;
                @include screen-md {
                    width: span(12 of 12);
                    order: -99;
                }
                &:after {
                    content: "";
                    @include position(absolute, 50%, 50%);
                    @include translate(-50%, -50%);
                    width: 80%;
                    height: 130px;
                    border: 1px solid #000;
                    z-index: 1;
                }
                h3 {
                    font: bold $h1;
                    width: 220px;
                    text-align: center;
                    position: relative;
                    background: #FFF;
                    min-height: 150px;
                    display: flex;
                    align-items: center;
                    z-index: 2;
                    padding: 0 gutter();
                    box-sizing: content-box;
                    @include screen-md {
                        padding: gutter();
                    }
                }
            }
        }
        .grey-bg {
            background: $grey-2;
            padding: gutter();
            .heading-box {
                width: span(4 of 12);
                @include flex(wrap, center, center);
                position: relative;
                @include screen-md {
                    width: span(12 of 12);
                }
                h3 {
                    font: bold $h1;
                    position: relative;
                }
            }
        }
        .white-bullets {
            height: 100%;
            width: span(8 of 12);
            padding: 40px gutter();
            margin-left: gutter();
            @include screen-md {
                width: 100%;
            }
            ul {
                li {
                    padding-right: 25px;
                    position: relative;
                    font: $p1;
                    margin-bottom: 5px;
                    &:after {
                        content: "";
                        width: 10px;
                        height: 10px;
                        background: #FFF;
                        @include position(absolute, 0.75em, $right: 0);
                        @include translate(0, -50%);
                    }
                }
            }
        }
        .blue-bullets {
            height: 100%;
            width: span(8 of 12);
            padding: 40px gutter();
            margin-left: gutter();
            @include screen-md {
                width: 100%;
            }
            ul {
                li {
                    padding-right: 25px;
                    position: relative;
                    font: $p1;
                    margin-bottom: 5px;
                    &:after {
                        content: "";
                        width: 10px;
                        height: 10px;
                        background: $blue;
                        @include position(absolute, 0.75em, $right: 0);
                        @include translate(0, -50%);
                    }
                }
            }
        }
    }