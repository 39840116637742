.home {

    .home section {
        min-height: 728px;
    }

    section.intro {
        position: relative;
        // background: url(../img/hero.jpg);
        background-size: cover;
        background-position: right center;
        min-height: 56vw;
        max-height: 100vh;
        @include flex(wrap, center, center);
        .bg-slider {
            @include position(absolute, 0, 0);
            width: 100%;
            height: 100%;
            z-index: 1;
            overflow: hidden;
            &:after {
                content: "";
                @include position(absolute, 0, 0,);
                width: 100%;
                height: 100%;
                background: rgba(#000, 0.3);
                // display: none;
                // @include screen-md {
                //     display: block;
                // }
            }
        }
        .container {
            height: 100%;
            padding: 60px gutter();   
            position: relative;
            z-index: 2;
            .right-side {
                @include flex(wrap, center, center, column);
                width: span(5 of 12);
                height: 100%;
                @include screen-md {    height: 735px;
                    width: span(12 of 12);
                }
                h2 {
                    font: bold $h1;
                    text-align: center;
                    color: #FFF;
                    max-width: 530px;
                    // margin: 0 auto;
                }
                .button {
                    margin: 60px 0;
                    font-weight: 700;
                }
                h3 {
                    font: 600 $h2;
                    text-align: center;
                    color: #FFF;
                    width: 400px;
                    max-width: 100%;
                    margin: 0 0;
                }
            }
        }
    }

    section:not(.intro) {
        @include screen-xl {
            @include full-height;
            &>.container {
                height: 100%;
            }
        }
    }

    section.about {
        background: $green;
        padding: 20px gutter();
        position: relative;
        min-height: 555px;
        @include screen-md {
            min-height: auto;
        }
        .bg-img {
            @include position(absolute, 0, $right: 0, $bottom: 0);
            height: 100%;
            width: auto;
            pointer-events: none;
            z-index: 1;
            @include screen-md {
                object-fit: cover;
                object-position: left;
                opacity: 0.1;
            }
        }
        .container {
            position: relative;
            z-index: 2;
            .about-inner {
                @include flex(wrap, center, center, column);
                h2 {
                    font: bold $h2;
                    text-align: center;
                    color: $grey-1;
                    // margin-bottom: 30px;
                    margin: gutter() auto;
                }
                .button {
                    // margin: 60px auto;
                    margin: gutter() auto;
                }
                blockquote {
                    color: $grey-1;
                    font: $h3;
                    text-align: center;
                    // margin-bottom: 100px;
                    margin: gutter() auto;
                }
                @include screen-md {
                    width: span(12 of 12);
                }
            }
        }
    }

    section.testimonials {
        background: $grey-2;
        padding: 20px gutter();
        min-height: 615px;
        @include screen-md {
            min-height: auto;
        }
        h2 {
            width: 100%;
            font: bold $h2;
            text-align: center;
            color: $grey-1;
            margin-bottom: 30px;
        }
        .testimonial-wrap {
            @extend .container;
            .testimonial {
                position: relative;
                width: span(4 of 12);
                margin-left: gutter();
                padding: 0 30px;
                @include screen-md {
                    width: span(12 of 12);
                    margin: 0 auto 30px;
                    padding: 0 gutter();
                    text-align: center;
                }
                &:after {
                    content: "";
                    @include position(absolute, 50%, 0);
                    @include translate(-50%, -50%);
                    width: 2px;
                    height: 230px;
                    background: #FFF;
                    display: block;
                    @include screen-md {
                        display: none;
                    }
                }
                &:last-child {
                    margin-left: 0;
                    &:after {
                        display: none;
                    }
                }
                .img-wrap {
                    width: 100px;
                    height: 100px;
                    margin: 0 auto 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        max-width: 100%;
                    }
                }
                .author {
                    color: $grey-1;
                    font: bold $h5;
                    text-align: center;
                    margin-bottom: gutter();
                }
                p {
                    color: $grey-1;
                    font: 300 $p1;
                }
            }
        }
        .button {
            margin: auto auto 0;
        }
    }

    section.gallery {
        position: relative;
        padding: 0 gutter();
        min-height: 555px;
        @include screen-md {
            min-height: auto;
        }
        .bg-img {
            @include position(absolute, 0, $left: 0, $bottom: 0);
            height: 100%;
            width: auto;
            pointer-events: none;
            z-index: 1;
            @include screen-md {
                object-fit: cover;
                object-position: center;
                opacity: 0.3;
            }
        }
        .content-wrap {
            position: relative;
            width: span(5 of 12);
            padding: 60px 0;
            z-index: 2;
            @include flex(nowrap, center, center, column);
            @include screen-md {
                width: span(12 of 12);
            }
            h2 {
                font: bold $h2;
                text-align: center;
                color: $grey-1;
                margin: gutter() 0;
            }
            p {
                color: $grey-1;
                font: $h3;
                text-align: center;
                margin-bottom: 100px;
            }
            .button {
                margin: 0 auto;
            }
        }
    }
    
    section.contact {
        padding: 0 gutter();
        background: $blue;
        min-height: 590px;
        @include screen-md {
            min-height: auto;
        }
        &> .container {
            height: 50%;
        }
        form {
            padding: 0 40px;
            margin: 0 auto;
            max-width: 1280px;
            @include screen-md {
                padding: 0 15px;
            }
            .submit {
                margin: auto auto 60px;
            }
            input {
                width: 100%;
            }
            .input-wrap.two-inputs span {
                width: 49%;
                input {
                    width: 100%;
                }
            }
            .wpcf7-mail-sent-ok {
                border: none;
                text-align: center;
                font: 600 27px/1.4 "Assistant", Arial, Helvetica, sans-serif;
            }
        }
        .contact-links {
            @extend .container;
            width: 570px;
            .block {
                position: relative;
                width: span(4 of 12);
                margin-left: gutter();
                
                &:last-child {
                    margin-left: 0;
                    &:after {
                        display: none;
                    }
                }
                img {
                    display: block;
                    margin: 0 auto;
                }
                a {
                    display: block;
                    text-align: center;
                    font: 600 $p1;
                    direction: ltr;
                    color: #000;
                    margin: 15px auto;
                    position: relative;
                    &:after {
                        content: "";
                        @include position(absolute, 0, 0);
                        @include translate(-50%, 0);
                        width: 2px;
                        height: 80px;
                        background: #FFF;
                        display: block;
                        @include screen-xs {
                            display: none;
                        }
                    }
                }
                &:last-child {
                    a {
                        &:after {
                            display: none;
                        }
                    }
                }
                @include screen-xs {
                    width: span(12 of 12);
                    margin: 0 auto 30px;
                }
            }
        }
        h2 {
            width: 100%;
            font: bold $h2;
            text-align: center;
            color: $grey-1;
            margin: 30px 0;
        }
    }

    section.lead {
        position: relative;
        // height: 735px;
        background: url(../img/shhar_pic.jpg);
        background-size: cover;
        background-position: center;
        @include screen-md {
            height: auto;
            padding: 30px gutter();
        }
        h2 {
            // @include position(absolute, 90px, 50%);
            // @include translate(-50%, 0);
            width: 1100px;
            max-width: 100%;
            font: bold $h1;
            font-size: 62px;
            text-align: center;
            color: $grey-1;
            background: rgba(#FFF, 0.60);
            padding: 10px 15px;
            border-radius: 15px;
            margin: auto auto 20px;
            @include screen-md {
                font: bold $h2;
                position: static;
                transform: none;
                margin-bottom: 60px;
            }
        }
        .bottom-buttons {
            // @include position(absolute, $bottom: 90px, $left: 50%);
            @include flex(wrap, space-between, center, column);
            // @include translate(-50%, 0);
            width: 1280px;
            max-width: 100%;
            margin:  auto;
            @include screen-md {
                position: static;
                transform: none;
            }
            .button {
                margin: 0 auto 60px;
                padding: 10px;
                @include screen-md {
                    margin: 0 auto 30px;
                }
                span {
                    display: block;
                    text-align: center;
                    font: $h3;
                    line-height: 1.2;
                    small {
                        display: block;
                        font-size: 0.8em;
                    }
                }
            }
        }
    }

    section.newsletter {
        padding: 40px gutter();
        background: url(../img/news.jpg);
        background-size: cover;
        background-position: center;
        min-height: 535px;
        #mc_embed_signup_scroll {
            // @include flex(wrap, center, center);
            width: 100%;
        }
        @include screen-md {
            min-height: auto;
        }
        h2 {
            width: 100%;
            font: bold $h2;
            text-align: center;
            color: $grey-1;
            // margin: 0 0 60px;
        }
        p {
            color: $grey-1;
            font: $h3;
            text-align: center;
            margin: gutter() auto;
            width: 650px;
            max-width: 100%;
        }
        .newsletter-form {
            margin: auto auto 0;
            width: 1100px;
            max-width: 100%;
        }
    }

}