.inner.trial-terms {
    section.hero {

    }
    section.main-content {
        .heading-box {
            h3 {
                width: fit-content;
            }
        }
        .blue-bg {
            .heading-box {
                min-height: 255px;
                &:after {
                    height: 100%;
                }
                .quote {
                    background: $blue;
                }
                .downquote {
                    bottom: 0;
                    @include translate(0, 50%);
                }
                .upquote {
                    top: 0;
                    @include translate(0, -50%);
                }
                @include screen-md {
                    min-height: auto;
                    .quote {
                        display: none;
                    }
                }
            }
            .white-bullets {
                padding: 20px;
            }
        }
        .green-bg {
            .button {
                margin: 60px auto 0;
            }
        }
    }
}